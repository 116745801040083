.avatar {
  height: 40px;
  width: 40px;
  background-color: red;
  border-radius: 50%;
  img {
    height: 40px;
    width: 40px;
  }
}

.buttonsNav {
  margin-right: 46px;
}

.titleApp {
  color: #313E51;
}

@media only screen and (max-width: 1000px) {
  .buttonsNav {
    margin-right: 22px;
  }
}

@media only screen and (max-width: 800px) {
  .appToolbar {
    color: white;
    --background: #313E51 !important;
  }

  .titleApp {
    color: white !important;
  }
}