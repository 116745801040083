.background-picture {
  width: 15%;
  height: 100%;
  position: relative;
}

.card-image {
  width: 100%;
  // height: 202px !important;
  position: relative !important;
}

.vertical-center {
  margin: 0;
  position: absolute;
  height: 100%;
}

.risk-picture {
  .deleteIcon {
    right: 3px;
    width: fit-content;
    position: relative;
    margin: 0 auto 0 10px;
    float: left;
    top: 30px;
    cursor: pointer;
    color: #f04141;
    font-size: 1.3rem;
  }
}

.item-slide {
  height: 90px;
}
