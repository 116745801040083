.appetite-manager {
  flex-direction: row;
  margin-left: 20px;
  margin-right : 20px;
  margin-top: 50px;

  .risk-actions {
    margin-left: 25px;

    ion-col>ion-button {
      width: 100%;
    }
  }
}

.appetite-labels {
  flex-direction: column;
  margin-left: 20px;
  margin-top: 20px;
  display: inline-flex;
  float: left;
}

ion-page {
  display: block
}

.headerTitle {
text-align: center;
  color: #313E51;
}
.apetite-btn {
  --background: #0086D1 !important;
  --box-shadow: none !important;
  --border-radius: 7px !important;
  text-transform: none !important;
  font-size: 16px;
}

