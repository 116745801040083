.notifyContent {
  max-width: 100%;
  //max-height: 56px;
}
.notifyContent {
  span, .contentNotification {
    display: none;
  }
}

.showSpan {
  display: grid !important;
  width: 100%;
  margin-top: 5px;
  border-top: gray solid 1px;
}

.showContentNotification {
  width: 100%;
 display: grid !important;
}