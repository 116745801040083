.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.row-wrapper {
  display: flex;
  font-size: 14px;

  .label-wrapper {
    background: #f4f4f4;
    width: 36px;
    padding-top: 21px;
    text-align: center;
    color: #0086D1;
    font-weight: bold;
  }
  .divion-border {
    background: #f4f4f4;
    width: 36px;
    padding-top: 21px;
    text-align: center;
    border-bottom: 2px solid #cecece;
    border-top: 2px solid #cecece;
    color: #0086D1;
    font-weight: bold;
  }

  .divion-border-0 {
    background: #f4f4f4;
    width: 36px;
    padding-top: 21px;
    text-align: center;
    color: #0086D1;
    font-weight: bold;
    border-bottom-left-radius: 100px 50px;
    border-bottom-right-radius: 100px 50px;

  }
  .division-border-2 {
    border-top-left-radius: 100px 50px;
    border-top-right-radius: 100px 50px;
    background: #f4f4f4;
    width: 36px;
    padding-top: 21px;
    text-align: center;
    color: #0086D1;
    font-weight: bold;
  }

}

