.card {
    width: 95%;
    margin:auto;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
}
.search-bar input {
    border-radius: 20px !important;
    box-shadow: none !important;
    background: #efefef !important;
    width: 96.5% !important;
}

.divSearchFilter {
    margin-top: 20px;
}

.search-bar {
    --icon-color: #0086D1 !important;
    --placeholder-color: #313E51 !important;
}
.headerTitle{
    text-align: center;
    color: #313E51;
}

.fab-btn {
    --background: #0086D1 !important;
}