
.headerTitle{
  text-align: center;
}

.report-btn {
  --border-radius: 7px !important;
  width: 30%;
  margin-left: 15px;
  min-width: 125px;
  text-transform: none;
}

