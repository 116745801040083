.general-modal {
  --border-radius: 14px !important;
}

.modal-btn {
  --background: none !important;
  --background-color: none !important;
  --box-shadow: none !important;
  --background-hover: var(--background);
}

.icon-btn {
  width: 24px !important;
  height: 24px !important;
}

.header-modal {
  --background: #313E51 !important;
}

.modal-title {
  color: #FFFFFF !important;
}