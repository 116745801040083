.possibility-wrapper {
    //margin-left: 20px;
    margin-left: 40px;
    text-align: center;
    margin-right: 6px;
    font-size: 14px;
    color: #0086d1;
    //margin-right: 35px;

    .year-span-label {
        width: auto;
        margin: auto;
        font-size: 14px;
        margin-top: 5px;
    }

    ion-label {
        font-size: 14px;
        text-align: center;
    }
}

.percentages {
    background: #f4f4f4;
    border-radius: 7px;
    font-weight: bold;
    .ten-fory {
        border-left: 2px solid #cecece;
        border-right: 2px solid #cecece;
        padding-top: 7px;

    }

    .fory-eig {
        border-right: 2px solid #cecece;
        padding-top: 7px;

    }

    #internal-number {
        padding-top: 7px;
    }
}