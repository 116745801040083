.card-register-employee {
  margin: 10px auto !important;
  border-radius: 20px !important;
  width: 95% !important;
  box-shadow: 5px 5px 15px 5px #bababa5e !important;
  min-width: 330px !important;
}

.form-text-area {
  background: #f4f4f4;
  border: none;
  border-radius: 10px;
  margin-top: 8px;
  textarea {
    height: 46px !important;
    padding: 10px 20px !important;
  }
  input{
    height: 46px !important;
    padding: 10px 20px !important;
  }
}

.form-label {
  --color: #0086D1 !important;
  font-size: 18px !important;
  padding-left: 20px;
}
