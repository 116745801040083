$goblacolor : #3880ff;

ion-card-header {
  background-color: $goblacolor;
}

.content {
  height: 75px !important;
}

ion-card-title {
  color: #FFFFFF;
}

.App {
  background-image: unset;
  .lorenzo1 {
    background-image: unset;
  }
}


