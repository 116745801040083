.custom-alert-class {
  .alert-head {
    margin-bottom: 10px;
    .alert-title {
      color: #0086D1;
      font-weight: bold;
    }
  }
  .alert-wrapper {
    border-radius: 10px;
    background: #313E51;
  }
  .alert-checkbox-group {
    background: #EAEAEA;
    color: #313E51;
  }
  .button-alert {
    color: #0086D1;
    text-transform: none !important;
    font-weight: bold !important;
    font-size: 16px;
  }
  //--border-radius: 14px;
  //--box-shadow: none;
}
.filter-btn {
  --background: none !important;
  --background-color: none !important;
  --box-shadow: none !important;
  --background-hover: var(--background);
  color: #313E51;
}