.impact-description-wrapper {
    margin-left: 20px;
    width: 137%;
    height: 200px;

    .order-style {
        background: #0086D1;
        padding: 8px;
        text-align: center;
        border-radius: 7px;
        color: white;
        font-weight: bold;
    }

    .order-1 {
        background: #0086D1;
        padding: 10.1px;
        text-align: center;
        border-radius: 7px;
        color: white;
        font-weight: bold;
    }

    .description-style {
        background: #f4f4f4;
        padding: 10px;
        border-radius: 7px;
        font-size: 14px;
        color: #0086D1;
    }
}

// ipad pro
@media only screen and (device-width: 1024px) {
    .impact-description-wrapper {
        margin-left: 20px;
        width: 200%;
        height: 234px;
    }
}


// ipad
@media only screen and (device-width: 768px) {
    .impact-description-wrapper {
        margin-left: 20px;
        width: 200%;
        height: 234px;
    }
}