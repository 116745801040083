.appetite-box {
  background: #fff;
  float: left;
  border: 1px solid #eae4e4;
  --box-shadow: none !important;
  border-radius: 7px !important;
  font-weight: bold;
  line-height: 34px;
  height: 70px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 100%;
}

.appetite-box:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}