.textInfo {
  width: 60%;
  margin: auto;
}


@media only screen and (max-width: 1000px) {
  .textInfo {
    width: 90%;
    text-align: justify;
  }
}