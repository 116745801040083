@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');
.contenedor {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #00aaee !important;
  font-family: 'Montserrat', sans-serif !important;
}

.grid-image {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.main-card {
  -webkit-border-radius: 46px !important;
  -moz-border-radius: 46px !important;
  border-radius: 46px !important;
  font-family: 'Montserrat', sans-serif !important;
  width: 100% !important;
  height: 100% !important;
  max-height: 362px !important;
}

.texto-card-1 {
  font-size: 26.5px !important;
  color: #0085D0;
  width: 101% !important;
  .ancho {
    font-weight: 700 !important;
  }
}

.register-text {
  font-size: 28px !important;
  margin-top: -18px !important;
  color: #0085D0;
  font-weight: 700 !important;
}

.card-content {
  padding: 32px !important;
}

.card-input {
  background-color: #f7f7f7 !important;
  border-radius: 16px !important;
  font-size: 16px !important;
  width: 514.5px !important;
  height: 55.7px !important;
  --padding-left: 15px;
}

.card-button {
  --border-radius: 16.8px !important;
  text-transform: none !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 700 !important;
  --background: #F49321;
  margin-left: -6px;
  width: 190px;
  height: 47.5px;
  font-size: 20.7px;
  color: #FFFFFF;
}

.card-button-login {
  --border-radius: 16.8px !important;
  text-transform: none !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 700 !important;
  --background: #0085D0;
  width: 190px;
  height: 47.5px;
  font-size: 20.7px;
  color: #FFFFFF;
}

.sesion-text {
  font-size: 12px;
  h5 {
    font-size: 1.4em;
    max-width: 246px;
    width: 246px;
  }
  .login_link_text {
    text-decoration: underline;
    cursor: pointer;
    color: #0085D0;
  }
}

.top-row {
  height: 20rem;
}

.lorenzo1 {
  height: 100vh;
  background-image: url('../../../../public/assets/images/fondo-version-web.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
  ion-content {
    //--background: #fff url('/assets/images/fondo-version-web.jpg')  no-repeat center center / cover;
  }
}

.landing-text {
  font-size: x-large;
  width: 84%;
  text-align: justify;
  color: white;

  .grid-title {
    text-align: left;
    font-size: 47px;
    font-family: 'Montserrat', sans-serif !important;
  }
  .grid-subtitle {
    font-family: 'Montserrat', sans-serif !important;
    text-align: justify;
    font-size: 30px;
    margin-top: 25px;
  }
}

.smart-show {
  color: white;
  text-align: center;
  font-size: xx-large;
  visibility: hidden;
  height: 20px;

}

@media only screen and (max-width: 1000px)  {
  .top-row {
    height: 2rem !important;
  }

  .main-card {
    width: 90% !important;
    height: unset !important;
    margin-left: 5% !important;
    max-height: unset !important;
    .card-content {
      .texto-card-1 {
        width: 108%;
      }
      .register-text {
        width: 101%;
        padding-top: 21px;
        font-size: 26px !important;
      }
      .card-input {
        padding-left: 16px !important;
        max-width: 95%;
      }
      .card-button {
        width: 171px;
        margin-left: 30px;
      }
      .card-button-login {
        width: 171px;
        margin-left: 30px;
      }
    }
  }

  .smart-show {
    visibility: inherit;
    height: fit-content;
  }
  .smart-hide {
    visibility: hidden;
    height: 1px;
  }

  .grid-title {
    font-family: 'Montserrat', sans-serif !important;
  }

  .lorenzo1 {
    font-family: Montserrat, sans-serif !important;
    background-image: url('../../../../public/assets/images/fondo-version-movil.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    ion-content {
      //--background: #fff url('/assets/images/fondo-version-web.jpg') no-repeat center center / cover;
    }
  }
}

// ipad
@media only screen and (width: 768px)  {
  .lorenzo1 {
    background-image: url('../../../../public/assets/images/Fondo-ipad.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: auto;
    ion-content {
      //--background: #fff url('/assets/images/fondo-version-web.jpg')  no-repeat center center / cover;
    }
  }

  .landing-text {
    text-align: justify;
    color: white;
    margin-left: 64px;
    .grid-title {
      text-align: justify;
      font-size: 29px;
      font-family: 'Montserrat', sans-serif !important;
    }

    .grid-subtitle {
      font-family: 'Montserrat', sans-serif !important;
      text-align: justify;
      font-size: 23px;
    }
  }

  .main-card {
    margin-left: 74px !important;
    border-radius: 46px !important;
    font-family: 'Montserrat', sans-serif !important;
    width: 80% !important;
  }

  .grid-image {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-justify-content: center !important;
    justify-content: center !important;
    -webkit-align-items: center !important;
    align-items: center !important;
    width: 204px !important;
    height: 36px !important;
  }

  .top-row {
    height: 11rem;
  }

  .card-input {
    max-width: 514px !important;
    padding-left: 11px !important;
  }


  .sesion-text {
    padding-right: 20px !important;
    margin-top: -61px !important;
    width: 100% !important;
    margin-left: 236px !important;
  }

}


@media only screen and (max-width: 767px)  {
 .landing-text {
   font-family: 'Montserrat', sans-serif !important;
   font-size: medium;
   .grid-title {
     font-size: large;
     font-family: 'Montserrat', sans-serif !important;
   }
 }
}

// ipad pro
@media only screen and (device-width: 1024px) {
  .lorenzo1 {
    background-image: url('../../../../public/assets/images/fondo-ipad-pro.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: auto;
    ion-content {
      //--background: #fff url('/assets/images/fondo-version-web.jpg')  no-repeat center center / cover;
    }
  }
  .landing-text {
    font-size: x-large;
    width: 100%;
    text-align: justify;
    color: white;
    /* margin-right: 235px; */
    margin-left: -30px;
      .grid-title {
        text-align: justify;
        font-size: 29px;
        font-family: 'Montserrat', sans-serif !important;
      }

    .grid-subtitle {
      font-family: 'Montserrat', sans-serif !important;
      text-align: justify;
      font-size: 23px;
    }
  }

  .top-row {
    height: 15rem;
  }

  .smart-show {
    height: 50px;
  }

  .card-input {
    max-width: 330px;
  }

  .card-button {
    width: unset !important;
    font-size: unset !important;
  }

  .card-button-login {
    width: unset !important;
    font-size: unset !important;
  }

  .sesion-text {
    padding-right: 20px !important;
    margin-top: -54px !important;
    width: 100% !important;
    font-size: 10px !important;
    margin-left: 140px !important;

  }
}

//@media only screen and (device-width: 1366px) {
//  .top-row {
//    height: 12rem;
//  }
//
//  .main-card {
//    border-radius: 46px !important;
//    font-family: 'Montserrat', sans-serif !important;
//    width: 100% !important;
//    height: 100% !important;
//    max-height: 296px !important;
//  }
//
//  .card-content {
//    padding: 24px !important;
//  }
//
//  .texto-card-1 {
//    font-size: 17.5px !important;
//    color: #0085D0;
//    width: 100% !important;
//  }
//
//  .imagen-card {
//    width: 145px;
//    height: 29px;
//  }
//  .register-text {
//    font-size: 24px !important;
//    margin-top: -18px !important;
//    color: #0085D0;
//    font-weight: 700 !important;
//  }
//
//  .card-input {
//    max-width: 346px;
//  }
//
//  .card-button {
//    width: unset !important;
//    font-size: unset !important;
//  }
//
//  .card-button-login {
//    width: unset !important;
//    font-size: unset !important;
//  }
//
//  .sesion-text {
//    padding-right: 20px !important;
//    margin-top: -58px !important;
//    width: 100% !important;
//    font-size: 10px !important;
//    margin-left: 140px !important;
//  }
//
//  .smart-show {
//    color: white;
//    text-align: center;
//    font-size: xx-large;
//    visibility: hidden;
//    height: 16px;
//  }
//
//  .landing-text {
//    font-size: x-large;
//    width: 91%;
//    text-align: justify;
//    color: white;
//    margin-left: -70px;
//  }
//
//  .landing-text .grid-subtitle {
//    font-family: 'Montserrat', sans-serif !important;
//    text-align: justify;
//    font-size: 22px;
//    margin-top: 15px;
//  }
//
//  .landing-text .grid-title {
//    text-align: justify;
//    font-size: 33px;
//    font-family: 'Montserrat', sans-serif !important;
//  }
//
//  .smart-show {
//    color: white;
//    text-align: center;
//    font-size: xx-large;
//    visibility: hidden;
//    height: 60px;
//  }
//}


// mac
@media only screen and (width: 1400px) {
  .top-row {
    height: 15rem;
  }

  .texto-card-1 {
    font-size: 23.5px !important;
    color: #0085D0;
    width: 104% !important;
  }

  .register-text {
    font-size: 27px !important;
    margin-top: -18px !important;
    color: #0085D0;
    font-weight: 700 !important;
  }

  .card-input {
    max-width: 370px;
  }

  .card-button {
    width: unset !important;
    font-size: unset !important;
  }

  .card-button-login {
    width: unset !important;
    font-size: unset !important;
  }

  .sesion-text {
    padding-right: 20px !important;
    margin-top: -54px !important;
    width: 100% !important;
    font-size: 10px !important;
    margin-left: 140px !important;
  }

  .smart-show {
    color: white;
    text-align: center;
    font-size: xx-large;
    visibility: hidden;
    height: 25px;
  }

  .landing-text {
    font-size: x-large;
    width: 91%;
    text-align: justify;
    color: white;
    margin-left: -70px;
  }

  .landing-text .grid-subtitle {
    font-family: 'Montserrat', sans-serif !important;
    text-align: justify;
    font-size: 25px;
    margin-top: 15px;
  }

  .landing-text .grid-title {
    text-align: justify;
    font-size: 38px;
    font-family: 'Montserrat', sans-serif !important;
  }

  .smart-show {
    color: white;
    text-align: center;
    font-size: xx-large;
    visibility: hidden;
    height: 60px;
  }

}

@media only screen and (min-width: 1000px) and (max-width: 1399px) {
  .top-row {
    height: 15rem;
  }

  .texto-card-1 {
    font-size: 23.5px !important;
    color: #0085D0;
    width: 104% !important;
  }

  .register-text {
    font-size: 27px !important;
    margin-top: -18px !important;
    color: #0085D0;
    font-weight: 700 !important;
  }

  .card-input {
    max-width: 370px;
  }

  .card-button {
    width: unset !important;
    font-size: unset !important;
  }

  .card-button-login {
    width: unset !important;
    font-size: unset !important;
  }

  .sesion-text {
    padding-right: 20px !important;
    margin-top: -54px !important;
    width: 100% !important;
    font-size: 10px !important;
    margin-left: 140px !important;
  }

  .smart-show {
    color: white;
    text-align: center;
    font-size: xx-large;
    visibility: hidden;
    height: 25px;
  }

  .landing-text {
    font-size: x-large;
    width: 91%;
    text-align: justify;
    color: white;
    margin-left: -70px;
  }

  .landing-text .grid-subtitle {
    font-family: 'Montserrat', sans-serif !important;
    text-align: justify;
    font-size: 25px;
    margin-top: 15px;
  }

  .landing-text .grid-title {
    text-align: justify;
    font-size: 38px;
    font-family: 'Montserrat', sans-serif !important;
  }

  .smart-show {
    color: white;
    text-align: center;
    font-size: xx-large;
    visibility: hidden;
    height: 60px;
  }
}

@media only screen and (device-width: 1466px) {
  .top-row {
    height: 12rem;
  }

  .main-card {
    border-radius: 46px !important;
    font-family: 'Montserrat', sans-serif !important;
    width: 100% !important;
    height: 100% !important;
    max-height: 332px !important;
  }

  .texto-card-1 {
    font-size: 19.5px !important;
    color: #0085D0;
    width: 100% !important;
  }

  .register-text {
    font-size: 22px !important;
    margin-top: -18px !important;
    color: #0085D0;
    font-weight: 700 !important;
  }

  .card-input {
    padding: 10px;
    max-width: 400px;
  }

  .card-button {
    width: 83% !important;
    font-size: 17.7px !important;
  }

  .card-button-login {
    width: 83% !important;
    font-size: 17.7px !important;
  }

  .sesion-text {
    padding-right: 20px !important;
    margin-top: -58px !important;
    width: 100% !important;
    font-size: 11px !important;
    margin-left: 148px !important;
  }

  .smart-show {
    color: white;
    text-align: center;
    font-size: xx-large;
    visibility: hidden;
    height: 25px;
  }

  .landing-text {
    font-size: x-large;
    width: 91%;
    text-align: justify;
    color: white;
    margin-left: -70px;
  }

  .landing-text .grid-subtitle {
    font-family: 'Montserrat', sans-serif !important;
    text-align: justify;
    font-size: 25px;
    margin-top: 15px;
  }

  .landing-text .grid-title {
    text-align: justify;
    font-size: 38px;
    font-family: 'Montserrat', sans-serif !important;
  }

  .smart-show {
    color: white;
    text-align: center;
    font-size: xx-large;
    visibility: hidden;
    height: 60px;
  }
}


// iphone 5
@media only screen and (max-width: 320px) {
  .card-input {
    padding-left: 16px !important;
    max-width: 211px !important;
    margin: -6px !important;
  }

  .card-button {
    margin-left: -1px !important;
  }

  .card-button-login {
    margin-left: -1px !important;
  }

  .sesion-text {
    padding-left: 8px !important;
    font-size: 10px !important;
    text-align: unset !important;
  }

  .texto-card-1 {
    font-size: 22.5px !important;
  }

  .register-text {
    font-size: 21px !important;
  }

  .login_link_text {
    cursor: pointer;
    color: #0085D0;
    margin-left: 20px !important;
  }

}

@media only screen and (device-width: 1496px) {
  .top-row {
    height: 15rem;
  }

  .texto-card-1 {
    font-size: 23.5px !important;
    color: #0085D0;
    width: 104% !important;
  }

  .register-text {
    font-size: 27px !important;
    margin-top: -18px !important;
    color: #0085D0;
    font-weight: 700 !important;
  }

  .card-input {
    max-width: 370px;
  }

  .card-button {
    width: unset !important;
    font-size: unset !important;
  }

  .card-button-login {
    width: unset !important;
    font-size: unset !important;
  }


  .sesion-text {
    padding-right: 20px !important;
    margin-top: -54px !important;
    width: 100% !important;
    font-size: 10px !important;
    margin-left: 140px !important;
  }

  .smart-show {
    color: white;
    text-align: center;
    font-size: xx-large;
    visibility: hidden;
    height: 25px;
  }

  .landing-text {
    font-size: x-large;
    width: 91%;
    text-align: justify;
    color: white;
    margin-left: -70px;
  }

  .landing-text .grid-subtitle {
    font-family: 'Montserrat', sans-serif !important;
    text-align: justify;
    font-size: 25px;
    margin-top: 15px;
  }

  .landing-text .grid-title {
    text-align: justify;
    font-size: 38px;
    font-family: 'Montserrat', sans-serif !important;
  }

  .smart-show {
    color: white;
    text-align: center;
    font-size: xx-large;
    visibility: hidden;
    height: 60px;
  }

}

@media only screen and (min-width: 1500px) and (max-width: 1700px) {
  .top-row {
    height: 12rem;
  }

  .texto-card-1 {
    font-size: 21.5px !important;
    color: #0085D0;
    width: 100% !important;
  }

  .register-text {
    font-size: 25px !important;
    margin-top: -18px !important;
    color: #0085D0;
    font-weight: 700 !important;
  }

  .card-input {
    max-width: 424px;
  }

  .card-button {
    width: 83% !important;
    font-size: 17.7px !important;
  }

  .card-button-login {
    width: 83% !important;
    font-size: 17.7px !important;
  }

  .sesion-text {
    padding-right: 20px !important;
    margin-top: -54px !important;
    width: 100% !important;
    font-size: 10px !important;
    margin-left: 148px !important;
  }

  .smart-show {
    color: white;
    text-align: center;
    font-size: xx-large;
    visibility: hidden;
    height: 25px;
  }

  .landing-text {
    font-size: x-large;
    width: 91%;
    text-align: justify;
    color: white;
    margin-left: -70px;
  }

  .landing-text .grid-subtitle {
    font-family: 'Montserrat', sans-serif !important;
    text-align: justify;
    font-size: 25px;
    margin-top: 15px;
  }

  .landing-text .grid-title {
    text-align: justify;
    font-size: 38px;
    font-family: 'Montserrat', sans-serif !important;
  }

  .smart-show {
    color: white;
    text-align: center;
    font-size: xx-large;
    visibility: hidden;
    height: 60px;
  }
}



@media only screen and (device-width: 1280px) {
  .top-row {
    height: 12rem;
  }

  .main-card {
    border-radius: 46px !important;
    font-family: 'Montserrat', sans-serif !important;
    width: 100% !important;
    height: 100% !important;
    max-height: 296px !important;
  }

  .card-content {
    padding: 24px !important;
  }

  .texto-card-1 {
    font-size: 17.5px !important;
    color: #0085D0;
    width: 100% !important;
  }

  .imagen-card {
    width: 145px;
    height: 29px;
  }
  .register-text {
    font-size: 24px !important;
    margin-top: -18px !important;
    color: #0085D0;
    font-weight: 700 !important;
  }

  .card-input {
    max-width: 346px;
  }

  .card-button {
    width: unset !important;
    font-size: unset !important;
  }

  .card-button-login {
    width: unset !important;
    font-size: unset !important;
  }

  .sesion-text {
    padding-right: 20px !important;
    margin-top: -58px !important;
    width: 100% !important;
    font-size: 10px !important;
    margin-left: 140px !important;
  }

  .smart-show {
    color: white;
    text-align: center;
    font-size: xx-large;
    visibility: hidden;
    height: 16px;
  }

  .landing-text {
    font-size: x-large;
    width: 91%;
    text-align: justify;
    color: white;
    margin-left: -70px;
  }

  .landing-text .grid-subtitle {
    font-family: 'Montserrat', sans-serif !important;
    text-align: justify;
    font-size: 22px;
    margin-top: 15px;
  }

  .landing-text .grid-title {
    text-align: justify;
    font-size: 33px;
    font-family: 'Montserrat', sans-serif !important;
  }

  .smart-show {
    color: white;
    text-align: center;
    font-size: xx-large;
    visibility: hidden;
    height: 60px;
  }
}

@media only screen and (device-width: 1440px) {
  .top-row {
    height: 11rem;
  }

  .texto-card-1 {
    font-size: 23.5px !important;
    color: #0085D0;
    width: 104% !important;
  }

  .register-text {
    font-size: 27px !important;
    margin-top: -18px !important;
    color: #0085D0;
    font-weight: 700 !important;
  }

  .card-input {
    max-width: 370px;
  }

  .card-button {
    width: unset !important;
    font-size: unset !important;
  }

  .card-button-login {
    width: unset !important;
    font-size: unset !important;
  }

  .sesion-text {
    padding-right: 20px !important;
    margin-top: -54px !important;
    width: 100% !important;
    font-size: 10px !important;
    margin-left: 140px !important;
  }

  .smart-show {
    color: white;
    text-align: center;
    font-size: xx-large;
    visibility: hidden;
    height: 25px;
  }

  .landing-text {
    font-size: x-large;
    width: 91%;
    text-align: justify;
    color: white;
    margin-left: -70px;
  }

  .landing-text .grid-subtitle {
    font-family: 'Montserrat', sans-serif !important;
    text-align: justify;
    font-size: 25px;
    margin-top: 15px;
  }

  .landing-text .grid-title {
    text-align: justify;
    font-size: 38px;
    font-family: 'Montserrat', sans-serif !important;
  }

  .smart-show {
    color: white;
    text-align: center;
    font-size: xx-large;
    visibility: hidden;
    height: 60px;
  }
}
