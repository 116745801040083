.control-card {
  box-shadow: none;
  background: #EAEAEA;
  border-radius: 7px;
}
.segment {
  margin: 7px;
  white-space: nowrap;
  --color: #313E51;
  --color-checked: white;
  --border-radius: 14px;
  font-weight: bold;
  --background-checked: #0086D1;
  --indicator-color: none;
  --background: white;
  text-transform: none !important;
  font-size: 17px;
  --background-hover: none;
  --background-hover-opacity: none;
  border: 2px solid #0086D1 !important;
}

@media only screen and (max-width: 1000px) {
  .itemRisk {
    margin-left: 40px;
  }

  .itemSlide {
    width: 105%;
  }
}