.risk-card-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}
.arrow-btn{
    align-self: self-end;
    position: relative;
    float: right;
    margin-top: unset !important;
    margin-right: unset !important;
    margin-left :20px;
    padding-left :1px;
    font-size: 5px;
    // background-color: unset !important;
}
.card-title-risk{
    // color:#4f5a6d;
    color: #313E51 !important;
    font-size: 16px;
    align-self: end;
    max-width: 70%;
    margin-top: 5px;
    margin-bottom: unset !important;
}
.my-content {
    border-left-style: double;
    border-left-width: 3px;
}
.wrap-div {
    float: right;
	clear: both;
	text-align: justify;
}
.wrap-div-right {
    float: right;
    width: 50%;
}
@mixin clearfix() {
    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }

.wrapTextRisk {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
  
  // Usage as a mixin
  .element {
    @include clearfix;
  }
// .risk-info {
//     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
//     display : grid;
//     width: 80%;
//     h4 {
//         font-weight: bold;
//     }
//     ion-label {
//         padding-left: 5px;
//     }
// }
// .left-btn {
//     float: right;
//     position: relative;
//     margin: auto;
//     ion-chip {
//         position: relative;
//         vertical-align: middle;
//         margin: 50% auto auto auto;
//     }
// }