// http://ionicframework.com/docs/theming/
@import '~@ionic/core/css/core.css';
@import '~@ionic/core/css/normalize.css';
@import '~@ionic/core/css/structure.css';
@import '~@ionic/core/css/typography.css';

@import '~@ionic/core/css/padding.css';
@import '~@ionic/core/css/float-elements.css';
@import '~@ionic/core/css/text-alignment.css';
@import '~@ionic/core/css/text-transformation.css';
@import '~@ionic/core/css/flex-utils.css';
@import 'components/App';
@import './theme/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat');

.delay-200ms {
  animation-delay: 0.02s !important;
}

.split-pane-visible > .split-pane-side {
  min-width: 300px !important;
  max-width: 300px !important;
}


* {
  font-family: 'Montserrat', sans-serif !important;
}

.ion-content-card {
  margin: 10px auto !important;
  border-radius: 20px !important;
  width: 95% !important;
  box-shadow: 5px 5px 15px 5px rgba(186, 186, 186, 0.37) !important;
  min-width: 330px !important;
}

.ionic-label {
  --color: #0086D1 !important;
  font-size: 18px !important;
  padding-left: 20px;
}

.ionic-textArea textarea {
  height: 46px !important;
  padding: 10px 20px !important;
}

.ionic-textArea {
  background: #f4f4f4;
  border: none;
  border-radius: 10px;

}

.ionic-input input {
  height: 46px !important;
  padding: 10px 20px !important;
}

.ionic-input {
  margin: 10px auto;
  background: #f4f4f4;
  border: none;
  border-radius: 10px;
}

.ionic-dataTime-padding {
  padding: 10px 20px !important;
}

.ionic-item {

  --background-hover: white !important;
  --color: gray;
  --color-activated: gray;
  --color-focused: white;
  --color-hover: gray;
  --ripple-color: white;
}

.ionic-color-header {
  --background:#313E51 !important;
}

.btn-report  {
  --border-radius: 7px !important;
  width: 30%;
  margin-left: 15px;
  min-width: 125px;
}

.loadingComponent {
  --spinner-color: #0086D1;
  --width: 250px !important;
  --height: 110px !important;
  font-size: 15px !important;
  font-weight: bold !important;
  .loading-wrapper {
    border-radius: 25px !important;
  }
}


@media only screen and (max-width: 1000px ) {
  .btn-report {
    --border-radius: 7px !important;
    width: 50%;
    margin-left: 15px;
    min-width: 125px;
  }
}

